import React from "react"
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import "./new-baby-track.css";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import Img from "gatsby-image";
import globalStyle from "../styles/global.module.css";


const NewBabyTrackPage = ({data}) => {

  return (
    <Layout className="site-content">
      <div className="new-baby-container">
        <div>
          <h1 className={globalStyle.entryTitle}>New Baby Virtual Village | 0-6 Months</h1>
        </div>

        <div>
          <Img fluid={data.allFile.edges[0].node.childImageSharp.fluid}></Img>
        </div>

        <div className="new-baby-content col-md-12">
          <div className="about-product">
            <p>
              The first six months with your baby is amazing but also full of adjustments in nearly every aspect of your life. You might be feeling overwhelmed, tired and we want to help! Our Virtual Village provides education and support to parents who are looking for information and connection all from the comfort of their home or office. Developmentally appropriate topics will be customized for each group. Topics include: feeding, sleeping, returning to work, keeping your relationship alive, traveling with baby, and so much more!
              <br /><br />
              Every parenting journey is different. We provide tips and strategies for your parenting “bag”, helping you find your confidence in your parenting skills and choices.
              <br /><br />
              Our goal is to provide a network of support and education to make your parenting journey more enjoyable and fulfilling!
              <br /><br />
              We have East Cost and West Coast village groups. Both groups meet Tuesdays beginning January 7th from 8-9:15PM in their Local Time zone. Please select West or East coast when you register.
            </p>
          </div>

          <div className="status">
            This product is currently out of stock and unavailable.
          </div>

          <div>
            <Tabs id="controlled-tab-example" className="tabTitle">
              <Tab eventKey="description" title="Description">
                <div className="description-content">
                  <h3>HOW DOES IT WORK?</h3>
                  <p>Each group has 6-8 new parents. Video conferencing is done using Zoom and all from the comfort of
                    your own home. Just click on the link we send you from your phone or your computer and you are instantly
                    face-timing with your group and our MOM Virtual Tribe Leaders.</p>

                  <p>Groups will meet Tuesdays beginning January 7th from 8-9:15PM.</p>

                  <h3>BONUS</h3>
                  <p>When you join our village, our MOM Educator, Ali Glinsky interviews and asks the hard questions you really
                    want to know from the experts in their field. The interviews include experts in Pediatric Nutrition,
                    Mindful Parenting, Personal Bran Style, a Sleep Consultant and much more! The interviews are all
                    accessible online so you can sit back and watch from the comfort of your home or office.
                    These interviews are sure to help you along in any part of your parenting journey!</p>
                </div>
              </Tab>
              <Tab eventKey="additional-information" title="Additional information" className="additional-information">
                <div className="informations-content">
                  <p>Session Options</p>
                  <span> West Coast: 6 Tuesdays Jan – Feb, East Coast: 6 Tuesdays Jan – Feb</span>
                </div>
              </Tab>
            </Tabs>
          </div>

          <Row className="justify-content-center row relatedProducts">
            <h2>Related products</h2>
            <Col md={10}>
              <Row className="toddlerTrack">
                <Col xs={12} md={6} sm={6} className="buttonContainer">
                  <Img fluid={data.allFile.edges[1].node.childImageSharp.fluid}></Img>
                  <Link className={globalStyle.redirectLink} to="/product/new-baby-virtual-village-0-6-months-1/">
                    <p>
                      Parenting Toddlers Virtual Village
                    </p>
                  </Link>
                  <div className="price">$180</div>
                </Col>
                <Col xs={12} md={6} sm={6} className="buttonContainer"></Col>
              </Row>
            </Col>
          </Row>

        </div>
      </div>
    </Layout>
  )
};

export const query = graphql`
  query {
    allFile(filter: {relativePath: {regex: "/parenting-village\/.*/"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
          name
        }
      }
    }
  }
`;

export default NewBabyTrackPage
